import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider, Theme, StyledEngineProvider, adaptV4Theme } from "@mui/material/styles";
import * as React from "react";

import { ListSubheader, Popover } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hook";
import MyIconSettings from "../../../components/MyIconSettings";
import { ITEM_TYPE } from "../../../utils/idToName";
import { setCollectionData, showModal } from "../Modal/CollectionModal/slice";
import { CollectionMorePopover } from "./CollectionMorePopover";
import { GrouopMorePopover } from "./GrouopMorePopover";
import { getMenuList, hideMenu, setMenuExpand } from "./RouterMenuSlice";
import { useSnackbar } from "notistack";




// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }




export default function ListMenuIndex() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  let { id } = useParams();
  // const [open, setOpen] = React.useState(true);
  // let [menulist,setMenuList] = useState<MenuListResponseDto>({group:[],collectionList:[]})
  let navigate = useNavigate();
  const { menulist,isMobile } = useAppSelector((state) => {
    return state.RouterMenuSlice;
  });
  const [visibleId, setVisibleId] = useState(-1);

  useEffect(() => {
    // TodolistItemService.menulist({ body: { id: 0 } }).then(res => {
    //   dispatch(setMenulist(res))
    // })
    // console.log('getList');
    dispatch(getMenuList({ id: 0 }));
  }, []);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [
    anchorElGroup,
    setAnchorElGroup,
  ] = React.useState<HTMLButtonElement | null>(null);
  const [
    anchorElCollection,
    setAnchorElCollection,
  ] = React.useState<HTMLButtonElement | null>(null);
  const MenuMoreShow = Boolean(anchorEl);
  return (
    <Box sx={{ display: "flex" }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider
          theme={createTheme(adaptV4Theme({
            // components: {
            //   MuiListItemButton: {
            //     defaultProps: {
            //       disableTouchRipple: true,
            //     },
            //   },
            // },
            palette: {
              mode: "dark",
              primary: { main: "rgb(102, 157, 246)" },
              // background: { paper: "rgb(5, 30, 52)" },
            },
          }))}
        >
          <Paper elevation={0} style={{borderRadius: 0}}>
            <Box
              className=" lg:w-64 lg:flex-col lg:flex lg:h-full overflow-auto"
              style={{height:'100vh',width:'100vw'}}
              // sx={{
              //   flexDirection: "column",
              //   "& .MuiListItemButton-root": {
              //     paddingLeft: 1,
              //   },
              //   "& .MuiListItemIcon-root": {
              //     minWidth: 0,
              //     marginRight: 1,
              //   },
              //   "& .MuiSvgIcon-root": {
              //     fontSize: 20,
              //   },
              // }}
            >
              <Divider />
              <ListItem
                component="div"
                disablePadding
                onClick={() => {
                  // navigate("/")
                  dispatch(
                    showModal({ name: "", desc: "", type: ITEM_TYPE.GROUP })
                  );
                }}
              >
                <ListItemButton sx={{ height: 56, width: 150 }}>
                  {/* <ListItemIcon>
                    <Home color="primary" />
                  </ListItemIcon> */}
                  <ListItemText
                    primary="创建分组"
                    primaryTypographyProps={{
                      color: "primary",
                      fontWeight: "medium",
                      variant: "body2",
                    }}
                  />
                </ListItemButton>
                {/* 创建集合的更多操作 */}
                <MyIconSettings
                  onClick={(event: any) => {
                    event.stopPropagation();
                    setAnchorEl(event.currentTarget);
                  }}

                />
              </ListItem>
              <Divider />
              {menulist.group.map((v, index) => {
                return (
                  <Box
                    sx={{
                      bgcolor: v.expend ? "rgba(71, 98, 130, 0.2)" : null,
                      pb: v.expend ? 2 : 0,
                    }}
                  >
                    <ListItemButton
                      alignItems="center"
                      sx={{
                        height: "60px",
                        alignItems: "center",
                        display: "flex",
                        paddingRight: 0,
                      }}
                      onMouseEnter={() => {
                        setVisibleId(v.id);
                      }}
                      onMouseLeave={() => {}}
                      onClick={() => {
                        if (v.secondaryText) {
                          dispatch(
                            setMenuExpand({ id: v.id, status: !v.expend })
                          );
                        }
                      }}
                    >
                      <ListItemText
                        primary={v.name}
                        primaryTypographyProps={{
                          fontWeight: "medium",
                          lineHeight: "20px",
                          mb: "2px",
                          color: "rgb(102, 157, 246)",
                          fontSize: 20,
                        }}
                        secondary={v.secondaryText ? v.secondaryText : null}
                        secondaryTypographyProps={{
                          noWrap: true,
                          fontSize: 12,
                          lineHeight: "16px",
                          color: v.expend
                            ? "rgba(0,0,0,0)"
                            : "rgba(255,255,255,0.5)",
                        }}
                        sx={{ my: 0 }}
                      />
                      {v.secondaryText ? (
                        <KeyboardArrowDown
                          sx={{
                            pr: 1,
                            pl: 1,
                            width: "auto",
                            opacity: 1,
                            transform: v.expend ? "rotate(-180deg)" : "rotate(0)",
                            transition: "0.2s",
                            fontSize:26
                          }}
                        />
                      ) : null}
                      {/* 分组的更多操作 */}
                      {(isMobile ||  (visibleId == v.id && v.id != -1)) ? (
                        <MyIconSettings
                          onClick={(event: any) => {
                            dispatch(setCollectionData(v));
                            event.stopPropagation();
                            setAnchorElGroup(event.currentTarget);
                          }}
                        />
                      ) : null}
                    </ListItemButton>
                    {menulist.collectionList.map((item) =>
                      item.parent == v.id && v.expend ? (
                        <ListItemButton
                          onClick={() => {
                            
                            navigate(
                              "/todolist-shortcut/" +
                                item.id +
                                "?name=" +
                                item.name
                            );
                            if(isMobile){
                              dispatch(hideMenu())
                            }
                          }}
                          onMouseEnter={() => {
                            setVisibleId(item.id);
                          }}
                          onMouseLeave={() => {
                            // setVisibleId(-2)
                          }}
                          key={item.name}
                          sx={{
                            height: 44,
                            pr: 0,
                            py: 0,
                            minHeight: 32,
                            color: "rgba(255,255,255,.8)",
                          }}
                        >
                          <ListItemText
                            primary={
                              "【" + item.todayItemCount + "】" + item.name
                            }
                            primaryTypographyProps={{
                              fontSize: 14,
                              fontWeight: "medium",
                            }}
                            sx={{ color: item.id == Number(id) ? "#00ffff" : "" }}
                          />
                          {/* 集合的更多操作 */}
                          {(isMobile ||visibleId == item.id )? (
                            <MyIconSettings
                              onClick={(event: any) => {
                                dispatch(setCollectionData(item));
                                event.stopPropagation();
                                setAnchorElCollection(event.currentTarget);
                                
                              }}
                            />
                          ) : null}
                        </ListItemButton>
                      ) : null
                    )}
                  </Box>
                );
              })}
            </Box>
          </Paper>
        </ThemeProvider>
      </StyledEngineProvider>
      <Popover
        open={MenuMoreShow}
        anchorEl={anchorEl}
        onClose={(event) => {
          // dispatch(setMenuMoreShow(false))
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          subheader={<ListSubheader>更多操作</ListSubheader>}
        >
          <ListItemButton
            onClick={(event) => {
              let userInfo = localStorage.getItem("userInfo")
              localStorage.clear()
              if(userInfo){
                localStorage.setItem("userInfo",userInfo)
              }
              // enqueueSnackbar("已清空本地缓存",{variant:"success",anchorOrigin:{vertical:'top',horizontal:'right'}})
              window.Message.Success("已清空本地缓存")
            }}
          >
            <ListItemText primary="清空缓存" />
          </ListItemButton>
          {/* <ListItemButton
            onClick={(event) => {
              // dispatch(showDrawer({ source: 'item', id: item.id }));
              
              dispatch(showModal({ name: "", desc: "", type: ITEM_TYPE.COLLECTION,parent:-1 }))
              setAnchorEl(null);
            }}
          >
            <ListItemText primary="创建集合（默认分组）" />
          </ListItemButton> */}
          {/* <ListItemButton
                    onClick={(event) => {
                        // dispatch(addSubItemModal({parent:item.parent, id:item.id,todolistId:todolistId,desc:item.desc,period:item.period}));
                        setAnchorEl(null);
                    }}
                >
                    <ListItemText primary="修改项目" />
                </ListItemButton>
                <ListItemButton
                    onClick={(event) => {
                        setAnchorEl(null);
                    }}
                >
                    <ListItemText primary="删除" />
                </ListItemButton> */}
        </List>
      </Popover>
      <GrouopMorePopover
        parentId={visibleId}
        anchorEl={anchorElGroup}
        onClose={() => {
          setAnchorElGroup(null);
        }}
      />
      <CollectionMorePopover
        anchorEl={anchorElCollection}
        onClose={() => {
          setAnchorElCollection(null);
        }}
      />
    </Box>
  );
}
