// import RouterMenu from "../components/RouterMenu/RouterMenu";
// import { Checkbox } from "@mui/material";

import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
// import { Todolist, TodolistCollection, TodolistCollectionService, TodolistItem, TodolistItemService, TodolistService } from "../../api/swaggerApi";
// import { Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useAppDispatch, useAppSelector } from "../../app/hook";
import { getData } from "./redux/slice";
// import { InitModal } from "./modal";
import FlagIcon from "@mui/icons-material/Flag";
import {
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Popover,
} from "@mui/material";
import Box from "@mui/material/Box";
import { UpdateTodolistCollectionModal } from "pages/components/Modal/CollectionModal";
import {
  alterModal,
  setCollectionData,
} from "pages/components/Modal/CollectionModal/slice";
import { ItemModal } from "pages/components/Modal/ItemModal";
import React from "react";
import { ITEM_TYPE } from "../../utils/idToName";
import { showDrawer } from "../components/CommentDrawer/slice";
import RouterMenu from "../components/RouterMenu/Index";
// import { setTodolistModalStatus } from "pages/components/ItemModal/slice";
import { debounce } from "lodash";
import ButtonAppBar from "pages/components/AppBar";
import { DeleteModal } from "pages/components/Modal/DeleteModal";
import {
  addItemModal,
  resetForm,
} from "pages/components/Modal/ItemModal/slice";
import { TodolistItemModal } from "pages/components/Modal/SubItemModal";
import {
  setIsMobile,
  setMenuShow,
} from "pages/components/RouterMenu/RouterMenuSlice";
import MyIconSettings from "../../components/MyIconSettings";
import { ListTemplate } from "./ListTemplate";

// import './shortcutStyle.css'

export default function ShortcutStyle() {
  const dispatch = useAppDispatch();

  let { id } = useParams();

  const { collectionData } = useAppSelector((state) => {
    return state.shortcutStyle;
  });
  const { form } = useAppSelector((state) => {
    return state.collection;
  });
  const { menuShow } = useAppSelector((state) => {
    return state.RouterMenuSlice;
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      if (window.innerWidth < 1024) {
        dispatch(setIsMobile(true));
      } else {
        dispatch(setIsMobile(false));
        dispatch(setMenuShow());
      }
      // console.log("Window width is now:", window.innerWidth);
    }, 500);
    // function handleResize() {
    //     if(window.length<1024){
    //         dispatch(setIsMobile(true))
    //     }else{
    //         dispatch(setIsMobile(false))
    //     }
    //   console.log("Window width is now:", window.innerWidth);
    // }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const [collectionData, setCollectionData] = useState<TodolistCollection>()
  // const [commentVisible, setCommentVisible] = useState(false);
  // const [addComment, setAddComment] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const fetchData = async () => {
    if (id) {
      try {
        let resultAction = await dispatch(
          getData({ id: (id as unknown) as number })
        );
        if (getData.fulfilled.match(resultAction)) {
          let data = resultAction.payload;
          // if (!form.name) {
          dispatch(setCollectionData(data.result));
          // }
        } else {
          if (resultAction.payload) {
            // showToast('error', `Update failed: ${resultAction.payload.errorMessage}`)
          } else {
            // showToast('error', `Update failed: ${resultAction.error.message}`)
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);
  // useEffect(()=>{
  //     if(!form.name){
  //         dispatch(setCollectionData({id:Number(id),name:collectionData?.name,desc:collectionData?.desc}))
  //     }
  // },[collectionData])

  // useEffect(()=>{

  // },[collectionData])
  // if (!collectionData) {
  //     return <div>加载中。。。</div>
  // }

  const COLLECTIONLIST = (
    <Box className="h-full w-full lg:overflow-hidden lg:overflow-x-scroll lg:p-0 ">
      {collectionData ? (
        <div className="overflow-auto lg:flex h-full w-full flex-col  p-3 flex-wrap content-start">
          <Box className="flex mb-3 overflow-auto item-width">
            <div
              className=" flex flex-row text-left w-full justify-between break-all cursor-pointer"
              onClick={() => {
                // dispatch(showDrawer({ id: listData.id }));
                dispatch(showDrawer({ id: collectionData.id }));
              }}
            >
              <Box className="flex flex-row">
                {collectionData.isComment ? (
                  <FlagIcon sx={{ fontSize: "12px" }} />
                ) : null}
                <p className="text-4xl">
                  {/* <a href={`/todolist-liststyle/detail/${collectionData?.id}?name=${collectionData?.name}`}>{collectionData?.name} </a> */}
                  {collectionData?.name}
                </p>
              </Box>
              <Box className="flex flex-col">
                <Tooltip title="更多操作">
                  <MyIconSettings
                    onClick={(event: any) => {
                      // dispatch(setCollectionData({ id: Number(id), name: collectionData.name, desc: collectionData.desc }))
                      event.stopPropagation();
                      setAnchorEl(event.currentTarget);
                    }}
                    color="black"
                  />
                </Tooltip>
              </Box>
            </div>
          </Box>
          {/* {(1)} */}
          {collectionData.subItem.map((v) => {
            return (
              <ListTemplate
                key={v.id}
                listData={v}
                
                // onRefresh={() => {
                //     getTodolistAll()
                // }}
              />
            );
          })}
        </div>
      ) : null}
    </Box>
  );

  return (
    <Box className="h-full flex flex-col">
      <ButtonAppBar />
      <Box
        className="flex break-all h-full overflow-hidden lg:pb-16"
        
      >
        {/* <RouterMenu /> */}
        {menuShow ? <RouterMenu /> : null}
        {/* {(menuShow) ? COLLECTIONLIST : null} */}
        {COLLECTIONLIST}

        <UpdateTodolistCollectionModal />
        <ItemModal />
        <TodolistItemModal />
        <DeleteModal />

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {collectionData ? (
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              subheader={<ListSubheader></ListSubheader>}
            >
              <ListItemButton
                onClick={() => {
                  dispatch(showDrawer({ id: collectionData.id }));
                  setAnchorEl(null);
                }}
              >
                <ListItemText primary="查看备注" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  setAnchorEl(null);
                  dispatch(alterModal({ type: ITEM_TYPE.COLLECTION }));
                }}
              >
                <ListItemText primary="修改集合" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  dispatch(resetForm());
                  dispatch(addItemModal());
                  setAnchorEl(null);
                }}
              >
                <ListItemText primary="添加项目" />
              </ListItemButton>
            </List>
          ) : null}
        </Popover>
      </Box>
    </Box>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
