import { Alert, Box, Button, Drawer } from "@mui/material";
import {
  hideDrawer,
} from "pages/components/QueryDrawer/slice";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hook";
import { ItemTemplate } from "pages/shortcutStyle/ItemTemplate";
import CollectionTemplate from "pages/shortcutStyle/CollectionTemplate";
import { ListTemplate } from "pages/shortcutStyle/ListTemplate";



export function QueryDrawer() {


  const {
    drawerShow,
    queryRes
  } = useAppSelector((state) => {
    return state.queryDrawerSlice;
  });
  const dispatch = useAppDispatch();




  const refDebounceSave = React.useRef<Function>();
  const refSave = React.useRef<any>();

  React.useEffect(() => {
    
  }, [drawerShow]);

  
  return (
    <Drawer
      anchor={"right"}
      open={drawerShow}
      onClose={() => {
        dispatch(hideDrawer());
      }}
      // className="CommentDrawer"
      //
    >
      <Box>
        
        <Button
          className="lg:hidden "
          color="warning"
          onClick={() => {
            // save()
            dispatch(hideDrawer());
          }}
        >
          关闭
        </Button>
      </Box>
      <Box
        sx={{
          background: "#f3f3f3",
          padding: "10px",
          height: "100%",
          overflow: "auto",
        }}
        className="drawer-width"
      >
        {/* <button onClick={(e) => {
          // set(e.target.value);
          dispatch(showDrawer({ id: 10 }));
        }}>cs</button> */}
        {queryRes?.itemres.map(v=>{
          return v.type==1?<CollectionTemplate
          key={v.id}
          todolistId={v.id}
          index={v.id}
          item={v}
        />:v.type==2?<ListTemplate
        key={v.id}
        
        listData={v}
      />:<ItemTemplate
          key={v.id}
          todolistId={v.id}
          index={v.id}
          item={v}
        />
        })}
        {/* {queryRes?.comment.map(v=>{
          return <ItemTemplate
          key={v.id}
          todolistId={v.id}
          index={v.id}
          item={v.}
        />
        })} */}
        
      </Box>
    </Drawer>
  );
}
