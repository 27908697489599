import { useEffect, useState } from "react";
import { list } from "postcss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CSSTransition } from "react-transition-group";
// Todolist, TodolistCollection, TodolistCollectionService, TodolistService,
import { TodolistItem, TodolistItemService } from "../../api/swaggerApi";

import { useAppDispatch, useAppSelector } from "../../app/hook";

import { ItemTemplate } from "./ItemTemplate";
import Box from "@mui/material/Box";
import {
  Badge,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Popover,
  Tooltip,
} from "@mui/material";
import { hideDrawer, showDrawer } from "../components/CommentDrawer/slice";
import React from "react";
// import { setTodolistItemModalStatus } from "pages/components/ItemModal/slice";
import {
  addItemModal,
  alterItemModal,
  setForm,
} from "pages/components/Modal/ItemModal/slice";
import { addSubItemModal, resetForm } from "pages/components/Modal/SubItemModal/slice";
import { showConfirm } from "pages/components/Modal/DeleteModal/slice";
import { setHoverId } from "./redux/slice";
import MyIconSettings from "../../components/MyIconSettings";
// import { setTodolistModalStatus } from "pages/components/ItemModal/slice";
import FlagIcon from "@mui/icons-material/Flag";

export function ListTemplate(props: {
  listData: TodolistItem;
}) {
  let { listData } = props;
  const dispatch = useAppDispatch();
  // let [listData, setListData] = useState([{ id: 1, checked: false }, { id: 2, checked: false }, { id: 3, checked: false }])

  let [renderListData, setRenderListData] = useState(listData);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  useEffect(() => {
    setRenderListData(listData);
  }, [listData]);
  const { itemDeleteNotConfirmAgain, hoverId } = useAppSelector((state) => {
    return state.shortcutStyle;
  });
  const { isMobile } = useAppSelector((state) => {
    return state.RouterMenuSlice;
  });


  return (
    <>
      {listData.queryRemark?<Box className=" text-gray-400 border-dashed pt-2 border-t-2 mt-2">{listData.queryRemark}</Box>:null}
      <Box
        onMouseEnter={() => {
          dispatch(setHoverId(listData.id));
        }}
        onClick={() => {
          dispatch(showDrawer({ id: listData.id }));
        }}
        sx={{
          paddingBottom: 1,
        }}
        className="item-width border-b-2 border-black mb-2  text-xl flex justify-between flex-row mt-4 cursor-pointer"
      >
        {/* <p><a href={`/todolist-liststyle/detail/?name=${listData?.name}`}>{listData.name} </a></p> */}
        <Box className="flex flex-row">
          {listData.isComment ? <FlagIcon sx={{ fontSize: "12px" }} /> : null}
          <p>{listData.name}</p>
        </Box>

        <Box sx={{ minWidth: "50px" }}>
          {(isMobile ||hoverId == listData.id) ? (
            <MyIconSettings
              onClick={(event: any) => {
                dispatch(setForm(listData));
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
              }}
              color="black"
            />
          ) : null}
        </Box>
      </Box>

      {renderListData.subItem&&renderListData.subItem.map((v, index) => {
        return (
          <CSSTransition key={v.id} timeout={500} classNames="item">
            <ItemTemplate
              key={v.id}
              todolistId={listData.id}
              index={index}
              item={v}
            />
          </CSSTransition>
        );
      })}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={(event) => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          subheader={<ListSubheader></ListSubheader>}
        >
          <ListItemButton
            onClick={(event) => {
              dispatch(showDrawer({ id: listData.id }));
              setAnchorEl(null);
            }}
          >
            <ListItemText primary="查看备注" />
          </ListItemButton>
          <ListItemButton
            onClick={(event) => {
              // dispatch(showDrawer({ source: 'list', id: listData.id }));
              dispatch(alterItemModal(listData));
              setAnchorEl(null);
            }}
          >
            <ListItemText primary="修改项目" />
          </ListItemButton>
          <ListItemButton
            onClick={(event) => {
              dispatch(showConfirm({ id: listData.id }));

              setAnchorEl(null);
            }}
          >
            <ListItemText primary="删除项目" />
          </ListItemButton>
          <ListItemButton
            onClick={(event) => {
              dispatch(resetForm())
              // dispatch(setForm(listData));
              dispatch(addSubItemModal());
              setAnchorEl(null);
            }}
          >
            <ListItemText primary="添加项目" />
          </ListItemButton>
        </List>
      </Popover>
    </>
  );
}
