import {
  createAsyncThunk,
  createSlice
} from "@reduxjs/toolkit";
import {
  // TodolistItem,
  TodolistItemCommentService
} from "../../../api/swaggerApi/index";

import { AxiosError } from 'axios';
import { ValidationErrors } from "../../../app/commoninterface";
interface CommentContentType {
  content: string
  createAt: string
  id: number
}
type sourceType = "item" | "list" | "collection"
export const showDrawer = createAsyncThunk<
  // 方法返回值
  { content: string,id:number } | false,
  // { res: TodolistItemComment, params: Partial<TodolistItem> } | null,
  // dispatch 参数
  { id: number },
  {
    rejectValue: ValidationErrors
  }
>('commentDrawerSlice/showDrawer', async (params, { rejectWithValue }) => {
  try {
    if (params.id) {
        let res = await TodolistItemCommentService.getContent({ body: { id: params.id } })

        return { content: res.content, id:params.id }
      
    }
    return false

  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data)
  }
})
export const saveComment = createAsyncThunk<
  // 方法返回值
  boolean,
  // { res: TodolistItemComment, params: Partial<TodolistItem> } | null,
  // dispatch 参数
  { id: number, content: string},
  {
    rejectValue: ValidationErrors
  }
>('commentDrawerSlice/saveComment', async (params, { rejectWithValue }) => {
  try {
    let res = await TodolistItemCommentService.update({ body: { content: params.content, id: params.id } })
      return true
    // if (params.id) {
    // if (params.source === 'item') {
      
    //   let res = await TodolistItemCommentService.update({ body: { todolistItemId: params.id, content: params.content, id: params.responseId } })
    //   return true
    // }
    // if (params.source === 'list') {
      
    //   let res = await TodolistCommentService.update({ body: { todolistId: params.id, content: params.content, id: params.responseId } })
    //   return true
    // }
    // if (params.source === 'collection') {
      
    //   let res = await TodolistCollectionCommentService.update({ body: { collectionId: params.id, content: params.content, id: params.responseId } })
    //   return true
    // }
    // }
    return false

  } catch (err: any) {
    let error: AxiosError<ValidationErrors> = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data)
  }
})
export const commentDrawerSlice = createSlice({
  name: "commentDrawerSlice",
  initialState: {
    isSaving:false,
    saveErr:false,
    drawerShow: false,
    isLoading: false,
    // source: <sourceType|undefined>undefined,
    content: '',
    id:0,
    // itemId: 0,
    // listId: 0,
    // collectionId: 0,
    responseId: <number | undefined>undefined


    // item: <TodolistItem | null>null,

    // commentContent: <TodolistItemComment | null>null
  },
  reducers: {
    
    setIsSavingFalse(state){
      state.isSaving = false

    },
    hideDrawer(state) {
      state.isSaving = false
      state.drawerShow = false
      state.content = ''
      state.id = 0
      // state.listId = 0
      // state.collectionId = 0
      state.responseId = undefined
      state.isLoading = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(showDrawer.pending, (state, { payload }) => {
      state.drawerShow = true
      state.isLoading = true
    })
    builder.addCase(showDrawer.fulfilled, (state, { payload }) => {
      state.isLoading = false
      console.log('showDrawer',payload)
      if (payload!=false) {
        state.content = payload.content
        // window.editor.render()
        // state.responseId = payload.responseId
        state.id = payload.id
        // if(payload.source === 'item'){
        //   state.itemId = payload.id
        //   state.source = 'item'
        // }
        // if(payload.source === 'list'){
        //   state.listId = payload.id
        //   state.source = 'list'
        // }
        // if(payload.source === 'collection'){
        //   state.collectionId = payload.id
        //   state.source = 'collection'
        // }
        // if(payload.userData){

        // state.item = payload.params as TodolistItem
        // }
      }
    })
    builder.addCase(showDrawer.rejected, (state, action) => {
      state.saveErr = true
    })
    builder.addCase(saveComment.pending, (state, { payload }) => {
      state.isSaving = false
      state.saveErr = false
    })
    builder.addCase(saveComment.fulfilled, (state, { payload }) => {
      state.isSaving = true
    })
  },
});

export const {
  setIsSavingFalse,
  // showDrawer,
  hideDrawer
} = commentDrawerSlice.actions;


export default commentDrawerSlice.reducer;
