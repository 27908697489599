import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './base.css';
import './index.css';
import store from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import './api/index';
import { SnackbarProvider } from 'notistack';
import moment from "moment";
import 'moment/locale/zh-cn';
import 'viewerjs/dist/viewer.css';

// import '../vendors/indexDB'

// @ts-ignore
import initReactFastclick from 'react-fastclick';
// @ts-ignore
// window.Viewer = Viewer

// // View an image.
// const viewer = new Viewer(document.getElementById('image'), {
//   // inline: true,
//   viewed() {
//     // viewer.zoomTo(1);
//   },
// });

initReactFastclick();

moment.locale('zh-cn',
{
    calendar: {
      sameDay: '[今天]',
      nextDay: '[明天]',
      nextWeek: '[下周]ddd',
      lastDay: '[昨天]',
      lastWeek: '[上周]ddd',
      sameElse: 'YYYY-MM-DD'
    },
  }
  );

// moment.locale('cn', {
//   calendar: {
//     sameDay: '[今天]',
//     nextDay: '[明天]',
//     nextWeek: '[下周]',
//     lastDay: '[昨天]',
//     lastWeek: '[上周] dddd',
//     sameElse: 'YYYY-MM-DD'
//   },
// });


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
