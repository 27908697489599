import { Box, Tooltip } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hook";
import MyIconSettings from "components/MyIconSettings";
import FlagIcon from '@mui/icons-material/Flag';
import { showDrawer } from "pages/components/CommentDrawer/slice";
import { TodolistItem } from "../../api/swaggerApi";
export default function CollectionTemplate(props: {
  item: TodolistItem;
  todolistId: number;
  index: number;
}) {
  //import { useAppDispatch, useAppSelector } from 'app/hook';
  const dispatch = useAppDispatch();
  // const { property  } = useAppSelector((state) => {
  //     return state.sliceName;
  // });
  return (
    <Box className="flex mb-3 overflow-auto item-width">
      <div
        className=" flex flex-row text-left w-full justify-between break-all cursor-pointer"
        onClick={() => {
          // dispatch(showDrawer({ id: listData.id }));
          dispatch(showDrawer({ id: props.item.id }));
        }}
      >
        <Box className="flex flex-row">
          {props.item.isComment ? (
            <FlagIcon sx={{ fontSize: "12px" }} />
          ) : null}
          <p className="text-4xl">
            {/* <a href={`/todolist-liststyle/detail/${collectionData?.id}?name=${collectionData?.name}`}>{collectionData?.name} </a> */}
            {props.item?.name}
          </p>
        </Box>
        <Box className="flex flex-col">
          <Tooltip title="更多操作">
            <MyIconSettings
              onClick={(event: any) => {
                // dispatch(setCollectionData({ id: Number(id), name: collectionData.name, desc: collectionData.desc }))
                event.stopPropagation();
                //   setAnchorEl(event.currentTarget);
              }}
              color="black"
            />
          </Tooltip>
        </Box>
      </div>
    </Box>
  );
}
