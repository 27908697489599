/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AppService {
  /**
   *
   */
  static profile(options: IRequestOptions = {}): Promise<jwtDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/profile';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadFile(options: IRequestOptions = {}): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/uploadFile';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static swaggerJson(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/swagger.json';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AuthService {
  /**
   *
   */
  static login(
    params: {
      /** requestBody */
      body?: LoginDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseUserInfoDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSts(
    params: {
      /** requestBody */
      body?: STSDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<STSDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/getSTS';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UsersService {
  /**
   *
   */
  static regiest(
    params: {
      /** requestBody */
      body?: LoginDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/users/regiest';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TodolistItemService {
  /**
   *
   */
  static setMenuExpand(
    params: {
      /** requestBody */
      body?: setMenuExpandDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<setMenuExpandDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/todolist-item/setMenuExpand';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static menulist(
    params: {
      /** requestBody */
      body?: idDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MenuListResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/todolist-item/menulist';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static list(
    params: {
      /** requestBody */
      body?: ListTodolistItemDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/todolist-item/list';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listToday(
    params: {
      /** requestBody */
      body?: ListTodolistItemTodayDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/todolist-item/listToday';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: UpdateTodolistItemDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/todolist-item/update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static detail(
    params: {
      /** requestBody */
      body?: ListTodolistItemDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseTodolistItemDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/todolist-item/detail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCollectionItems(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TodolistItem[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/todolist-item/getCollectionItems';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setStatus(
    params: {
      /** requestBody */
      body?: SetStatusDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/todolist-item/set-status';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /** requestBody */
      body?: idDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/todolist-item/delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CommonService {
  /**
   *
   */
  static query(
    params: {
      /** requestBody */
      body?: QueryDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<QueryResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/todolist-common/query';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLast(options: IRequestOptions = {}): Promise<QueryResponseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/todolist-common/getLast';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TodolistItemCommentService {
  /**
   *
   */
  static getAllKey(options: IRequestOptions = {}): Promise<object[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/todolist-item-comment/getAllKey';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllUrl(options: IRequestOptions = {}): Promise<object[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/todolist-item-comment/getAllUrl';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateImageKey(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/todolist-item-comment/update-image-key';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateTodolistItemCommentDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/todolist-item-comment/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getContent(
    params: {
      /** requestBody */
      body?: idQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TodolistItemComment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/todolist-item-comment/getContent';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: UpdateTodolistItemCommentDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/todolist-item-comment/update';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /** requestBody */
      body?: idDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/todolist-item-comment/delete';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface jwtDto {
  /**  */
  userId: number;

  /**  */
  username: string;
}

export interface LoginDto {
  /**  */
  username: string;

  /**  */
  password: string;
}

export interface User {
  /**  */
  id: number;

  /**  */
  isActive: boolean;

  /**  */
  username: string;

  /**  */
  password: string;

  /**  */
  regiestAt: string;
}

export interface ResponseUserInfoDto {
  /**  */
  user: User;

  /**  */
  access_token: string;
}

export interface STS_CREDENTIALS {
  /**  */
  sessionToken: string;

  /**  */
  tmpSecretId: string;

  /**  */
  tmpSecretKey: string;
}

export interface STSDto {
  /**  */
  expiredTime: number;

  /**  */
  expiration: string;

  /**  */
  credentials: STS_CREDENTIALS;

  /**  */
  requestId: string;

  /**  */
  startTime: number;
}

export interface setMenuExpandDto {
  /**  */
  id: number;

  /**  */
  status: boolean;
}

export interface idDto {
  /**  */
  id: number;
}

export interface TodolistItem {
  /**  */
  id: number;

  /**  */
  isDeleted: boolean;

  /**  */
  secondaryText: string;

  /**  */
  todayItemCount: number;

  /**  */
  expend: boolean;

  /**  */
  isComment: boolean;

  /**  */
  belongUser: number;

  /**  */
  parent: number;

  /**  */
  tipTodolistId: number;

  /**  */
  tipCollectionId: number;

  /**  */
  period: number;

  /**  */
  name: string;

  /**  */
  todolistName: string;

  /**  */
  desc: string;

  /**  */
  type: number;

  /**  */
  status: number;

  /**  */
  updateTime: string;

  /**  */
  startAt: string;

  /**  */
  pauseAt: string;

  /**  */
  queryRemark: string;

  /**  */
  commentCount: number;

  /**  */
  timeCount: number;

  /**  */
  subItem: TodolistItem[];

  /**  */
  endTime: string;
}

export interface MenuListResponseDto {
  /**  */
  group: TodolistItem[];

  /**  */
  collectionList: TodolistItem[];
}

export interface ListTodolistItemDto {
  /**  */
  collection?: number;

  /**  */
  todolist?: number;

  /**  */
  startAt?: string;

  /**  */
  endAt?: string;

  /**  */
  type?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;
}

export interface ListTodolistItemTodayDto {
  /**  */
  collection: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;
}

export interface UpdateTodolistItemDto {
  /** 状态 */
  type?: number;

  /**  */
  id?: number;

  /**  */
  parent?: number;

  /**  */
  todolistId?: number;

  /**  */
  name?: string;

  /**  */
  desc?: string;

  /**  */
  startAt?: string;

  /**  */
  pauseAt?: string;

  /**  */
  period?: number;
}

export interface ResponseTodolistItemDto {
  /**  */
  result: TodolistItem;
}

export interface SetStatusDto {
  /** 状态 */
  status: number;

  /**  */
  id: number;

  /**  */
  todolistId: number;
}

export interface QueryDto {
  /**  */
  query: string;
}

export interface QueryResponseDto {
  /**  */
  itemres: TodolistItem[];
}

export interface CreateTodolistItemCommentDto {
  /**  */
  todolistItemId: number;

  /**  */
  content: string;

  /**  */
  createAt?: string;
}

export interface idQuery {
  /**  */
  id: number;
}

export interface TodolistItemComment {
  /**  */
  id: number;

  /**  */
  isDeleted: object;

  /**  */
  belongUser: number;

  /**  */
  updateTime: string;

  /**  */
  content: string;

  /**  */
  isRead: boolean;

  /**  */
  createAt: string;

  /**  */
  belongItem: number;
}

export interface UpdateTodolistItemCommentDto {
  /**  */
  content: string;

  /**  */
  id?: number;
}
